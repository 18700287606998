<template>
  <div>
    <p class="title-secondary m__bottom--20 p--0">ご利用者一覧</p>
    <div class="page-main-content page-list-data m__top--20">
      <div class="columns mt-0">
        <div class="column p__bottom--0 p__top--0 is-half has-text-left">
          <button
              class="button mr-3 btn-save-small p-0 background__pink color__white"
              @click="createServiceUser()"
          >
            <i class="fas fa-plus m__right--5"></i>
            {{ $t("user_service.buttons.create") }}
          </button>
          <button
              class="button mr-3 btn-delete-small p-0 color__white"
              v-if="hasChecked" @click="deleteAll"
          ><i class="fas fa-trash m__right--5"></i>
            {{ $t("buttons.delete") }}
          </button>
        </div>
      </div>
      <div class="columns d-flex pt-3">
        <div
            class="column p__bottom--0 p__top--0 is-align-self-flex-end is-half-mobile is-half-desktop is-half-fullhd is-half-tablet is-half-widescreen is-half-touch">
          <span class="vertical-baseline-middle">全{{ paginate.total || 0 }}件</span>
        </div>
        <div
            class="column p__bottom--0 p__top--0 has-text-right align-items-center is-half-mobile is-half-desktop is-half-fullhd is-half-tablet is-half-widescreen is-half-touch">
            <span class="m__right--5 vertical-baseline-middle">{{ $t('page.page-range') }}</span>
          <div
              class="dropdown is-right"
              id="dropdown-pagination"
              v-click-out="closeDropPageRange"
          >
            <div class="dropdown-trigger align-items-center d-flex">
              <button
                  class="button btn-change-page-range p__top--0 p__bottom--0"
                  aria-haspopup="true"
                  @click="toggleDropPageRange()"
              >
                <span class="w--80 has-text-right p__right--5">{{ paginate.perPage }} 件</span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a
                    @click="changePageRange(e)"
                    v-for="(e, i) in pageRanges"
                    :key="'page-range-' + i"
                    class="dropdown-item p__right--10 font-16"
                >
                  {{ e }} 件
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="columns">
          <div class="column p__top--10">
            <div class="table-container data-table">
              <table class="table is-striped is-hoverable is-fullwidth p--12">
                <thead class="has-text-centered">
                <tr>
                  <th class="input-title text-center p--12 col-checkbox">
                    <label class="checkbox">
                      <input @change="toggleCheckAll()" type="checkbox" class="is-radiusless" v-model="checkAll" :disabled="disabledAll">
                    </label>
                  </th>
                  <th class="input-title p--12">
                    {{ $t("user_service.input_field.employee_id") }}
                  </th>
                  <th class="input-title p--12">
                    {{ $t("user_service.input_field.user_name") }}
                  </th>
                  <th class="input-title p--12 col-action">
                    {{ $t("user_service.input_field.action") }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(e, i) in serviceUsers" :key="'user' + i">
                  <td class="has-text-centered">
                    <label class="checkbox">
                      <input
                          :disabled="e.documents.length > 0"
                          @change="checkItem(e)"
                          type="checkbox" class="is-radiusless"
                          v-model="e.checked"
                      />
                    </label>
                  </td>
                  <td class="">{{ e.code }}</td>
                  <td class="">{{ e.name }}</td>
                  <td class="has-text-centered">
                    <div class="d-flex is-justify-content-center">
                      <button
                          :data-tooltip="$t('tooltip.edit')"
                          @click="updateServiceUser(e.id)"
                          class="blob is-action action-success m__right--10 has-tooltip-top"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button :data-tooltip="(e.documents.length <= 0 && displayTooltip) && $t('tooltip.delete')"
                              :disabled="e.documents.length > 0"
                              @click="deleteServiceUser(e)"
                              :class="{'disabled': e.documents.length > 0}"
                              class="blob is-action action-danger has-tooltip-top"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr v-if="!serviceUsers || !serviceUsers.length">
                  <td colspan="7" class="has-text-centered">
                    {{ $t("target_not_found") }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-full has-text-centered m__top--10">
            <pagination
                :current="paginate.currentPage"
                :per-page="paginate.perPage"
                :total="paginate.total"
                @change="changePage($event)"
            >
            </pagination>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import {ACTION_SET_ACTIVE_SIDEBAR, ACTION_SET_SEARCH_PARAMS} from "@/stores/common/actions";
import {UserUsageService} from "@/services";
import findIndex from "lodash/findIndex";

export default {
  name: "UserServiceList",
  data() {
    return {
      paginate: {
        currentPage: null,
        lastPage: null,
        perPage: null,
        total: null,
      },
      pageRanges: [10, 20, 50, 100],
      serviceUsers: [],
      checkAll: false,
      hasChecked: false,
      pushItem: [],
      displayTooltip: true,
      disabledAll: true,
    };
  },
  methods: {
    closeDropPageRange() {
      this.$el
          .querySelector("#dropdown-pagination")
          .classList.remove("is-active");
    },
    toggleDropPageRange() {
      this.$el
          .querySelector("#dropdown-pagination")
          .classList.toggle("is-active");
    },
    changePageRange(range) {
      this.paginate.perPage = range;
      this.hasChecked = false;
      this.checkAll = false;
      this.closeDropPageRange();
      this.getUserService(1, this.paginate.perPage);
    },
    async getUserService(page, limit) {
      let data = {page, limit}
      this.$store.dispatch(ACTION_SET_SEARCH_PARAMS, {key: 'userService', data})
      await UserUsageService.list(page, limit)
          .then((res) => {
            if (res && res.data) {
              let flag = true
              this.serviceUsers = res.data.service_users.map((e) => {
                if(e.documents.length <= 0) {
                  flag = false
                }
                e.checked = false
                return e
              })
              this.disabledAll = flag
              this.pushItem = []
              this.paginate = {
                currentPage: res.data.current_page,
                lastPage: res.data.last_page,
                perPage: res.data.per_page * 1,
                total: res.data.total,
              };
            }
          })
          .catch((err) => {
            this.$toast.error(err.data.message);
          });
    },
    changePage(page) {
      this.hasChecked = false;
      this.checkAll = false;
      this.serviceUsers.map((e) => {
        if (e.checked) {
          this.pushItem.splice(this.pushItem.indexOf(e.id));
        }
      });
      this.getUserService(page, this.paginate.perPage);
    },
    createServiceUser() {
      this.$router.push({name: "UserServiceCreate"}, () => {
      });
    },
    updateServiceUser(id) {
      this.$router.push(
          {name: "UserServiceUpdate", params: {id}},
          () => {
          }
      );
    },
    toggleCheckAll() {
      this.pushItem = []
      this.hasChecked = false
      this.serviceUsers.map((e) => {
        if (e.documents.length <= 0) {
          e.checked = this.checkAll
        }
        if (e.checked) {
          this.hasChecked = true
        }
      })
      if (this.checkAll) {
        this.serviceUsers.map((e) => {
          if (e.documents.length <= 0) {
            this.pushItem = [...this.pushItem, ...[e.id]]
          }
        })
      }
    },
    checkItem(e) {
      if (!e.checked) {
        this.checkAll = false
        let index = findIndex(this.pushItem, el => el === e.id)
        if (index >= 0) {
          this.pushItem.splice(index, 1)
        }
      } else {
        this.hasChecked = true
        this.pushItem = [...this.pushItem, ...[e.id]]
      }
      if (!this.pushItem.length) {
        this.hasChecked = false
      } else if (this.pushItem.length === this.serviceUsers.filter((e) => e.documents.length <= 0).length) {
        this.checkAll = true
      }
    },
    deleteServiceUser(e) {
      if (e.documents && e.documents.length) {
        this.$toast.warning(
            this.$t("user_service.messages.user_has_documents")
        )
      } else {
        this.displayTooltip = false
        this.$popup(
            this.$t("user_service.dialog.title"),
            this.$t("user_service.dialog.content"),
            {
              okText: this.$t("users_management.popup.delete_user.ok"),
              cancelText: this.$t("users_management.popup.delete_user.cancel"),
              iconClass: "info-circle",
              type: "warning",
            },
            {
              onOk: async (d) => {
                await UserUsageService.delete(e.id)
                    .then(() => {
                      this.checkAll = false
                      this.$toast.success(
                          this.$t("user_service.messages.delete_success")
                      );
                      this.getUserService(
                          this.paginate.currentPage,
                          this.paginate.perPage
                      );
                      this.hasChecked = false
                    })
                    .catch((err) => {
                      console.log(err)
                    });
                d.closeDialog();
                this.displayTooltip = true
              },
              onCancel: (d) => {
                d.closeDialog();
                this.displayTooltip = true
              },
              onClickOut: (d) => {
                d.closeDialog()
                this.displayTooltip = true
              }
            }
        );
      }
    },
    deleteAll() {
      this.$popup(
          this.$t("users_management.popup.delete_user.title"),
          this.$t("users_management.popup.delete_user.content"),
          {
            okText: this.$t("users_management.popup.delete_user.ok"),
            cancelText: this.$t("users_management.popup.delete_user.cancel"),
            iconClass: "info-circle",
            type: "warning",
          },
          {
            onOk: async (d) => {
              let data = {
                ids: this.pushItem.join(","),
                _method: "DELETE",
              };
              await UserUsageService.deleteAll(data)
                  .then(() => {
                    this.checkAll = false
                    this.$toast.success(
                        this.$t("user_service.messages.delete_success")
                    );
                    this.getUserService(
                        this.paginate.currentPage,
                        this.paginate.perPage
                    );
                    this.hasChecked = false
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              d.closeDialog();
            },
            onCancel: (d) => {
              d.closeDialog();
            },
          }
      );
    },
  },
  created() {
    this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, "user-service")
    this.paginate.currentPage = 1
    this.paginate.perPage = 10
    let params = this.$store.getters['searchParams'].userService
    if (params && Object.keys(params).length) {
      this.paginate.currentPage = params.page
      this.paginate.perPage = params.limit
    }
    this.getUserService(this.paginate.currentPage, this.paginate.perPage)
  },
};
</script>

<style lang="scss" scoped>
.blob {
  width: 34px;
  height: 34px;
  top: 1px;
  left: 1px;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;

  &.disabled {
    background-color: #CCCCCC !important;
    cursor: not-allowed !important;
  }

  &:hover {
    cursor: pointer;
  }
}

.table {
  thead {
    tr {
      th {
        vertical-align: middle;
      }
    }
  }

  tbody {
    tr {
      &.is-checked {
        background-color: #ecd6e5 !important;
      }
    }
  }

  .col-action {
    width: 10%
  }
}

@media screen and (max-width: 768px) {
  .page-list-data {
    .columns {
      .column:first-child,
      .column:nth-child(2) {
        padding: 10px !important;
      }
    }
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  button {
    &.is-action.action-success {
      padding: 0 !important;
    }
  }
  .button {
    &.btn-change-page-range {
      padding: 0 0 0 15px !important
    }

    &.btn-save-small {
      padding: 0 !important;
    }

    &.btn-delete-small {
      padding: 0 !important;
    }
  }
  .data-table th {
    &.col-checkbox {
      min-width: 50px !important;
    }
  }

}
</style>
